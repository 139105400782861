import { UnorderedListOutlined } from "@ant-design/icons";
import { Button, Input, InputNumber, Layout, Modal, Select, Space } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Spinner } from "src/core/components/Spinner";
import { categoryService } from "src/core/services/categoryService";
import { menuService } from "src/core/services/menuService";
import { textColors } from "src/core/style/colors";
import { MenuItemImageControl } from "src/modules/menuItemImage/MenuItemImageControl";
import { Category, MenuItem } from "src/types/types";

type Props = {
  menuItemProps?: MenuItem;
  activeState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  onSuccess?: () => void;
};

const { Option } = Select;
const { TextArea } = Input;

export const MenuItemEditModal = (props: Props) => {
  const [menuItem, setMenuItem] = useState<MenuItem>({
    title: "",
    description: "",
    categoryId: undefined,
    price: undefined,
    secondPrice: undefined,
    menuId: 0,
    orderNumber: 0,
    hidden: false,
    spicy: false,
    vegetarian: false,
    vegan: false,
    new: false,
    top: false,
  });

  const [loading, setLoading] = useState(true);
  const [userCategories, setUserCategories] = useState<Category[]>([]);
  const [submit, setSubmit] = useState(false);

  const { t } = useTranslation();
  const history = useHistory();

  const {
    activeState: [active, setActive],
  } = props;

  useEffect(() => {
    if (props.menuItemProps) {
      setMenuItem(props.menuItemProps);
    }
  }, [props.menuItemProps]);

  useEffect(() => {
    (async () => {
      if (props.menuItemProps?.menuId) {
        const response = await categoryService.fetchUserCategoriesByMenuId(
          props.menuItemProps?.menuId
        );
        setUserCategories(response);

        setLoading(false);
      }
    })();
  }, [props.menuItemProps?.menuId]);

  const save = async () => {
    setSubmit(true);

    await menuService.updateMenuItem(menuItem);

    setSubmit(false);

    if (props.onSuccess) {
      props.onSuccess();
    }
  };

  return (
    <Modal
      open={active}
      title={t("modal.title.menuItemEdit")}
      footer={null}
      onCancel={() => {
        setActive(false);
      }}
    >
      <Layout>
        <Content style={{ background: "white" }}>
          <Input
            autoFocus
            placeholder={t("input.title")}
            value={menuItem.title}
            onChange={(evt) => {
              setMenuItem({ ...menuItem, title: evt.target.value });
            }}
            style={{ marginBottom: 20 }}
            required
          />
          <TextArea
            placeholder={t("input.descriptionOptional")}
            autoSize={{ minRows: 3, maxRows: 6 }}
            value={menuItem.description}
            onChange={(evt) => {
              setMenuItem({ ...menuItem, description: evt.target.value });
            }}
            style={{ marginBottom: 20 }}
          />
          <Space>
            <p style={{ marginBottom: 20 }}>
              <b>{t("input.orderNumber")}:</b>
            </p>
            <InputNumber
              placeholder={t("input.orderNumber")}
              value={menuItem.orderNumber}
              onChange={(value) => {
                setMenuItem({
                  ...menuItem,
                  orderNumber: value ?? 0,
                });
              }}
              style={{ marginBottom: 20 }}
            />
          </Space>
          <Select
            showSearch
            filterOption={(input, option) => {
              if (!Array.isArray(option?.children)) {
                return (
                  (option?.children ?? "")
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                );
              } else {
                return (
                  option?.children[option?.children.length - 1]?.props.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                );
              }
            }}
            loading={loading}
            value={menuItem.categoryId}
            placeholder={t("input.selectCategory")}
            style={{ width: "100%", marginBottom: 20 }}
            onChange={(value: any) => {
              setMenuItem({ ...menuItem, categoryId: value });
            }}
          >
            {userCategories.map((cat: Category, index) => {
              const isParent = userCategories.find(
                (c) => c.parentId === cat.id
              );

              return (
                <Option
                  key={`menu-item-edit-${index}`}
                  value={cat.id || 0}
                  disabled={isParent !== undefined}
                >
                  {(cat?.parentId || 0) > 0 && (
                    <span style={{ width: 10, display: "inline-block" }}></span>
                  )}
                  <span
                    style={{
                      textDecoration: cat.hidden ? "line-through" : "none",
                    }}
                  >
                    {cat.title}
                  </span>
                </Option>
              );
            })}
          </Select>
          <Input
            type="number"
            placeholder={t("input.price")}
            value={menuItem.price}
            onChange={(evt) =>
              setMenuItem({
                ...menuItem,
                price: evt.target.valueAsNumber,
              })
            }
            style={{ marginBottom: 20, marginTop: 5 }}
            required
          />
          <Input
            type="number"
            placeholder={t("input.price2")}
            value={menuItem.secondPrice}
            onChange={(evt) =>
              setMenuItem({
                ...menuItem,
                secondPrice: evt.target.valueAsNumber,
              })
            }
            style={{ marginBottom: 20, marginTop: 5 }}
          />
          <MenuItemImageControl
            value={menuItem?.imageUrl}
            setValue={async (value?: string | null) => {
              setMenuItem({
                ...menuItem,
                imageUrl: value,
              });
            }}
            disablePreview={menuItem?.disablePreview}
            setDisablePreview={(value) => {
              setMenuItem({
                ...menuItem,
                disablePreview: value,
              });
            }}
          />
          <Space style={{ width: "100%" }}>
            <p style={{ marginBottom: 20 }}>
              <b>{t("input.spicy")}:</b>
            </p>
            <Checkbox
              checked={menuItem.spicy}
              onChange={(value) => {
                setMenuItem({
                  ...menuItem,
                  spicy: value.target.checked,
                });
              }}
              style={{ marginBottom: 20, marginRight: 5 }}
            />
            <p style={{ marginBottom: 20 }}>
              <b>{t("input.vegetarian")}:</b>
            </p>
            <Checkbox
              checked={menuItem.vegetarian}
              onChange={(value) => {
                setMenuItem({
                  ...menuItem,
                  vegetarian: value.target.checked,
                });
              }}
              style={{ marginBottom: 20, marginRight: 5 }}
            />
            <p style={{ marginBottom: 20 }}>
              <b>{t("input.vegan")}:</b>
            </p>
            <Checkbox
              checked={menuItem.vegan}
              onChange={(value) => {
                setMenuItem({
                  ...menuItem,
                  vegan: value.target.checked,
                });
              }}
              style={{ marginBottom: 20 }}
            />
          </Space>
          <Space style={{ width: "100%" }}>
            <p style={{ marginBottom: 20 }}>
              <b>{t("input.new")}:</b>
            </p>
            <Checkbox
              checked={menuItem.new}
              onChange={(value) => {
                setMenuItem({
                  ...menuItem,
                  new: value.target.checked,
                });
              }}
              style={{ marginBottom: 20, marginRight: 5 }}
            />
            <p style={{ marginBottom: 20 }}>
              <b>{t("input.top")}:</b>
            </p>
            <Checkbox
              checked={menuItem.top}
              onChange={(value) => {
                setMenuItem({
                  ...menuItem,
                  top: value.target.checked,
                });
              }}
              style={{ marginBottom: 20, marginRight: 5 }}
            />
          </Space>
          <Space style={{ width: "100%" }}>
            <p style={{ marginBottom: 30 }}>
              <b>{t("input.hidden")}:</b>
            </p>
            <Checkbox
              checked={menuItem.hidden}
              onChange={(value) => {
                setMenuItem({
                  ...menuItem,
                  hidden: value.target.checked,
                });
              }}
              style={{ marginBottom: 30 }}
            />
          </Space>
        </Content>
        <Content style={{ textAlign: "right", background: "white" }}>
          <Button
            style={{ width: 160, marginRight: 20 }}
            onClick={() => {
              history.push(
                `/admin/categories?menuId=${props.menuItemProps?.menuId}`
              );
            }}
            disabled={false}
          >
            <UnorderedListOutlined />
            {t("title.menuCategories")}
          </Button>
          <Button
            type="primary"
            style={{ width: 100 }}
            onClick={() => {
              save();
            }}
            disabled={false}
          >
            {submit ? (
              <Spinner size="small" color={textColors.white} />
            ) : (
              t("button.save")
            )}
          </Button>
        </Content>
      </Layout>
    </Modal>
  );
};
