import { CloseOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Image, Skeleton, Space, Upload, notification } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAccessToken } from "src/authProvider";
import { API_BASE_URL } from "src/constants";
import { menuDesignService } from "src/core/services/menuDesignService";
import { MenuCustomDesign } from "src/types/types";

type Props = {
  menuIdentifier: string;
  onChange?: (data: MenuCustomDesign) => Promise<void>;
  disabled?: boolean;
  labelNote?: string;
};

export const MenuCustomBackgroundControl = (props: Props) => {
  const { menuIdentifier, onChange, disabled, labelNote } = props;

  const [loading, setLoading] = useState(true);
  const [menuCustomDesign, seMenuCustomDesign] = useState<MenuCustomDesign>();

  const { t } = useTranslation();

  const menuBackgroundUploadProps = {
    name: "file",
    action: `${API_BASE_URL}/files/backgrounds/upload`,
    headers: {
      authorization: `Bearer ${getAccessToken()}`,
    },
    onChange(info: any) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        seMenuCustomDesign({ backgroundImage: info.file.response.url });

        menuDesignService.saveMenuCustomDesign(menuIdentifier, {
          ...menuCustomDesign,
          backgroundImage: info.file.response.url,
        });

        notification.open({
          message: t("message.info"),
          type: "success",
          description: t("message.menuBackgroundSaved"),
        });
      } else if (info.file.status === "error") {
        notification.open({
          message: t("message.info"),
          type: "error",
          description: t("message.unexpectedError"),
        });
      }
    },
  };

  useEffect(() => {
    (async () => {
      if (disabled) {
        setLoading(false);
      } else {
        setLoading(true);

        const response = await menuDesignService.getMenuCustomDesign(
          menuIdentifier
        );
        seMenuCustomDesign(response);

        setLoading(false);
      }
    })();
  }, [disabled, menuIdentifier]);

  useEffect(() => {
    (async () => {
      if (menuCustomDesign) {
        if (onChange) {
          await onChange(menuCustomDesign);
        }
      }
    })();
  }, [menuCustomDesign, onChange]);

  return (
    <Skeleton loading={loading} active>
      <Space style={{ marginBottom: 15 }}>
        <Space direction="vertical" size={0}>
          <p style={{ marginBottom: 0, color: disabled ? "gray" : undefined }}>
            <b>{t("input.menuBackground")}:</b>
          </p>
          {labelNote && (
            <span
              style={{ fontSize: 12, color: disabled ? "gray" : undefined }}
            >
              {labelNote}
            </span>
          )}
        </Space>
        {menuCustomDesign?.backgroundImage &&
        menuCustomDesign?.backgroundImage !== "" ? (
          <Space>
            <Image
              src={menuCustomDesign?.backgroundImage}
              style={{ maxHeight: 80 }}
              preview={false}
            />
            <CloseOutlined
              onClick={async () => {
                seMenuCustomDesign({ backgroundImage: "" });

                menuDesignService.saveMenuCustomDesign(menuIdentifier, {
                  ...menuCustomDesign,
                  backgroundImage: "",
                });

                notification.open({
                  message: t("message.info"),
                  type: "info",
                  description: t("message.menuBackgroundRemoved"),
                });
              }}
            />
          </Space>
        ) : (
          <Upload
            {...menuBackgroundUploadProps}
            accept={".png, .jpg, .jpeg"}
            maxCount={1}
            style={{ marginBottom: 20 }}
            disabled={disabled}
          >
            <Button disabled={disabled} icon={<UploadOutlined />}>
              {t("input.file")}
            </Button>
          </Upload>
        )}
      </Space>
    </Skeleton>
  );
};
