import {
  AlignLeftOutlined,
  CheckSquareOutlined,
  FormatPainterOutlined,
  LineChartOutlined,
  QrcodeOutlined,
  QuestionCircleOutlined,
  ReadOutlined,
  StarOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Layout, Menu, Space, Tooltip } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useViewPortQuery } from "src/core/hooks/useViewPortQuery";

type pageName =
  | "admin"
  | "menus"
  | "multi-menus"
  | "categories"
  | "help"
  | "orders"
  | "menu-designs";

type Props = {
  page?: pageName;
};

const { Sider } = Layout;

const Title = styled.span`
  font-size: 27px;
  line-height: 35px;
  font-family: "Segoe UI Light";
  cursor: pointer;
`;

const BrandStack = styled("div")`
  cursor: pointer;
  width: 100%;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
`;

export const LeftSidebar = (props: Props) => {
  const history = useHistory();
  const isMobile = useViewPortQuery() === "mobile";

  const [isCollapsed, setIsCollapsed] = useState(isMobile);

  const { t } = useTranslation();

  const menuItems = [
    {
      key: "admin",
      icon: <UserOutlined />,
      onClick: () => history.push("/admin"),
      label: (
        <Tooltip placement="right" title={t("sidebar.home")}>
          {t("sidebar.home")}
        </Tooltip>
      ),
    },
    {
      key: "menus",
      icon: <AlignLeftOutlined />,
      onClick: () => history.push("/admin/menus"),
      label: (
        <Tooltip placement="right" title={t("sidebar.userMenus")}>
          {t("sidebar.userMenus")}
        </Tooltip>
      ),
    },
    {
      key: "categories",
      icon: <UnorderedListOutlined />,
      onClick: () => history.push("/admin/categories"),
      label: (
        <Tooltip placement="right" title={t("sidebar.categories")}>
          {t("sidebar.categories")}
        </Tooltip>
      ),
    },
    {
      key: "multi-menus",
      icon: <ReadOutlined />,
      onClick: () => history.push("/admin/multi-menus"),
      label: (
        <Tooltip placement="right" title={t("sidebar.multiMenus")}>
          {t("sidebar.multiMenus")}
        </Tooltip>
      ),
    },
    {
      key: "menu-designs",
      icon: <FormatPainterOutlined />,
      onClick: () => history.push("/admin/menu-designs"),
      label: (
        <Tooltip placement="right" title={t("sidebar.designs")}>
          {t("sidebar.designs")}
        </Tooltip>
      ),
    },
    {
      key: "orders",
      icon: <CheckSquareOutlined />,
      onClick: () => history.push("/admin/orders"),
      label: (
        <Tooltip placement="right" title={t("sidebar.orders")}>
          {t("sidebar.orders")}
        </Tooltip>
      ),
    },
    {
      key: "qr-generator",
      icon: <QrcodeOutlined />,
      disabled: true,
      label: (
        <Tooltip placement="right" title={t("sidebar.soon")}>
          {t("sidebar.qrGenerator")}
        </Tooltip>
      ),
    },
    {
      key: "reviews",
      icon: <StarOutlined />,
      disabled: true,
      label: (
        <Tooltip placement="right" title={t("sidebar.disabledMessage")}>
          {t("sidebar.reviews")}
        </Tooltip>
      ),
    },
    {
      key: "statistics",
      icon: <LineChartOutlined />,
      disabled: true,
      label: (
        <Tooltip placement="right" title={t("sidebar.disabledMessage")}>
          {t("sidebar.statistics")}
        </Tooltip>
      ),
    },
    {
      key: "help",
      icon: <QuestionCircleOutlined />,
      onClick: () => history.push("/admin/help"),
      label: (
        <Tooltip placement="right" title={t("sidebar.help")}>
          {t("sidebar.help")}
        </Tooltip>
      ),
    },
  ];

  return (
    <Sider
      collapsible
      defaultCollapsed={isMobile}
      onCollapse={(collapsed: boolean) => {
        setIsCollapsed(collapsed);
      }}
      style={{
        height: "100vh",
        left: 0,
      }}
    >
      <BrandStack>
        <Title
          onClick={() => {
            history.push("/admin");
          }}
          style={{ color: "white" }}
        >
          {isMobile || isCollapsed ? "ML" : "MenuLink"}
        </Title>
      </BrandStack>
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={props.page ? [props.page] : []}
        items={menuItems}
      />
      <Space
        style={{
          padding: 5,
          position: "absolute",
          bottom: 40,
          width: "100%",
          justifyContent: "center",
          textAlign: "center",
        }}
        direction="vertical"
      >
        <Button
          style={{
            fontSize: isMobile || isCollapsed ? 12 : 14,
            border: "white",
            backgroundColor: "gold",
            color: "black",
            borderRadius: 20,
            whiteSpace: "break-spaces",
          }}
          target="_blank"
          href="https://www.paypal.com/donate/?business=VAHKM6KPP7V2G&no_recurring=1&item_name=Net+ir+ma%C5%BEa+vienkartin%C4%97+parama+mums+pad%C4%97s+toliau+palaikyti+ir+pl%C4%97sti+MenuLink.+A%C4%8Di%C5%AB%21&currency_code=EUR"
        >
          <span style={{ fontWeight: 600 }}>{t("button.donate")}</span>
        </Button>
        <p
          style={{
            color: "white",
            textAlign: "center",
            fontSize: isMobile || isCollapsed ? 12 : 14,
            lineHeight: 1.1,
          }}
        >
          {t("message.donateMessage")}
        </p>
      </Space>
    </Sider>
  );
};
