import { CloseOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Image,
  Input,
  Layout,
  Modal,
  notification,
  Select,
  Space,
  Upload,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAccessToken } from "src/authProvider";
import { API_BASE_URL } from "src/constants";
import { Spinner } from "src/core/components/Spinner";
import { menuDesignService } from "src/core/services/menuDesignService";
import { multiMenuService } from "src/core/services/multiMenuService";
import { textColors } from "src/core/style/colors";
import { MenuCustomBackgroundControl } from "src/modules/menuCustomBackground/MenuCustomBackgroundControl";
import { MenuDesign, MultiMenu } from "src/types/types";

type Props = {
  activeState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  onSuccess?: () => void;
};

const { Option } = Select;
const { TextArea } = Input;

export const MultiMenuCreateModal = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const [menuDesigns, setMenuDesigns] = useState<MenuDesign[]>();
  const [newMenu, setNewMenu] = useState<MultiMenu>({
    title: "",
    description: "",
  });

  const [titleRequiredError, setTitleRequiredError] = useState(false);

  const [submit, setSubmit] = useState(false);

  const { t } = useTranslation();

  const {
    activeState: [active, setActive],
  } = props;

  const uploadProps = {
    name: "file",
    action: `${API_BASE_URL}/files/images/upload`,
    headers: {
      authorization: `Bearer ${getAccessToken()}`,
    },
    onChange(info: any) {
      if (info.file.status !== "uploading") {
        //console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        console.log(info.file);
        setNewMenu({
          ...newMenu,
          headerLogo: `${info.file.response.url}`,
        });
      } else if (info.file.status === "error") {
        // handle error
      }
    },
  };

  useEffect(() => {
    (async () => {
      const response = await menuDesignService.getMenuDesigns();
      setMenuDesigns(response);

      setLoading(false);
    })();
  }, []);

  const createMenu = async () => {
    setTitleRequiredError(false);

    if (newMenu.title?.trim() === "") {
      setTitleRequiredError(true);
      return;
    }

    setSubmit(true);
    await multiMenuService.createMultiMenu(newMenu);
    setSubmit(false);

    if (props.onSuccess) {
      notification.success({
        message: t("label.info"),
        description: t("message.multiMenuCreated"),
        onClick: () => {},
        top: 90,
      });

      props.onSuccess();
    }

    setNewMenu({
      title: "",
      description: "",
    });
  };

  return (
    <Modal
      open={active}
      title={t("modal.title.newMultiMenu")}
      footer={null}
      onCancel={() => {
        setActive(false);
        setNewMenu({
          title: "",
          description: "",
        });
      }}
    >
      <Layout>
        <Content style={{ background: "white" }}>
          <Input
            autoFocus
            placeholder={t("input.title")}
            value={newMenu.title}
            onChange={(evt) => {
              setNewMenu({ ...newMenu, title: evt.target.value });
            }}
            style={{
              marginBottom: 20,
              borderColor: titleRequiredError ? "red" : undefined,
            }}
            required
          />
          <TextArea
            placeholder={t("input.descriptionOptional")}
            autoSize={{ minRows: 3, maxRows: 6 }}
            value={newMenu.description}
            onChange={(evt) => {
              setNewMenu({ ...newMenu, description: evt.target.value });
            }}
            style={{ marginBottom: 20 }}
          />
          <Select
            loading={loading}
            value={newMenu.menuDesignId}
            placeholder={t("input.selectStyle")}
            style={{ width: "100%", marginBottom: 20 }}
            onChange={(value: any) => {
              setNewMenu({ ...newMenu, menuDesignId: value });
            }}
          >
            {menuDesigns?.map((item: MenuDesign, index) => {
              return (
                <Option key={`mm-c-${index}`} value={item?.id || 0}>
                  {item?.title}
                </Option>
              );
            })}
          </Select>
          <Space style={{ marginBottom: 15 }}>
            <p style={{ marginBottom: 5 }}>
              <b>{t("input.menuLogo")}:</b>
            </p>
            {newMenu.headerLogo && newMenu.headerLogo !== "" ? (
              <Space>
                <Image
                  src={newMenu.headerLogo}
                  style={{ maxHeight: 80 }}
                  preview={false}
                />
                <CloseOutlined
                  onClick={() => {
                    setNewMenu({ ...newMenu, headerLogo: "" });
                  }}
                />
              </Space>
            ) : (
              <Upload
                {...uploadProps}
                accept={".png, .jpg, .jpeg"}
                maxCount={1}
                style={{ marginBottom: 20 }}
              >
                <Button icon={<UploadOutlined />}>{t("input.file")}</Button>
              </Upload>
            )}
            <Input
              type={"hidden"}
              placeholder={t("input.menuLogo")}
              value={newMenu.headerLogo}
              onChange={(evt) => {
                setNewMenu({ ...newMenu, headerLogo: evt.target.value });
              }}
              style={{ marginBottom: 20 }}
              required
            />
          </Space>
          <Space>
            <MenuCustomBackgroundControl
              menuIdentifier={""}
              disabled={true}
              labelNote={t("message.enabledOnlyAfterMenuIsCreated")}
            />
          </Space>
        </Content>
        <Content style={{ textAlign: "right", background: "white" }}>
          <Button
            type="primary"
            style={{ width: 100 }}
            onClick={() => {
              createMenu();
            }}
            disabled={false}
          >
            {submit ? (
              <Spinner size="small" color={textColors.white} />
            ) : (
              t("button.save")
            )}
          </Button>
        </Content>
      </Layout>
    </Modal>
  );
};
