import { CloseOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Image,
  Skeleton,
  Space,
  Upload,
  notification,
} from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getAccessToken } from "src/authProvider";
import { API_BASE_URL } from "src/constants";

type Props = {
  value?: string | null;
  setValue: (value?: string | null) => void;
  disablePreview?: boolean;
  setDisablePreview?: (value: boolean) => void;
  disabled?: boolean;
  labelNote?: string;
};

export const MenuItemImageControl = (props: Props) => {
  const {
    value,
    disabled,
    labelNote,
    setValue,
    disablePreview,
    setDisablePreview,
  } = props;

  const { t } = useTranslation();

  const uploadProps = {
    name: "file",
    action: `${API_BASE_URL}/files/menu-items/upload`,
    headers: {
      authorization: `Bearer ${getAccessToken()}`,
    },
    onChange(info: any) {
      if (info.file.status !== "uploading") {
        // pending
      }
      if (info.file.status === "done") {
        // success
        setValue(info.file.response.url);
      } else if (info.file.status === "error") {
        notification.open({
          message: t("message.info"),
          type: "error",
          description: t("message.unexpectedError"),
        });
      }
    },
  };

  useEffect(() => {
    (async () => {})();
  }, []);

  return (
    <Skeleton loading={false} active>
      <Space style={{ marginBottom: 15 }} size={20}>
        <Space direction="vertical" size={0} align="baseline">
          <p style={{ marginBottom: 0, color: disabled ? "gray" : undefined }}>
            <b>{t("input.menuItemImage")}:</b>
          </p>
          {labelNote && (
            <span
              style={{ fontSize: 12, color: disabled ? "gray" : undefined }}
            >
              {labelNote}
            </span>
          )}
        </Space>
        {value ? (
          <Space align="baseline">
            <Space direction="vertical">
              <Image src={value} style={{ maxHeight: 80 }} />
              <Space>
                <p style={{ marginBottom: 20 }}>
                  <b>{t("input.disablePreview")}:</b>
                </p>
                <Checkbox
                  checked={disablePreview}
                  onChange={(value) => {
                    setDisablePreview?.(value.target.checked);
                  }}
                  style={{ marginBottom: 20, marginRight: 5 }}
                />
              </Space>
            </Space>

            <CloseOutlined
              onClick={async () => {
                setValue("");
              }}
            />
          </Space>
        ) : (
          <Upload
            {...uploadProps}
            accept={".png, .jpg, .jpeg"}
            maxCount={1}
            style={{ marginBottom: 20 }}
            disabled={disabled}
          >
            <Button disabled={disabled} icon={<UploadOutlined />}>
              {t("input.file")}
            </Button>
          </Upload>
        )}
      </Space>
    </Skeleton>
  );
};
