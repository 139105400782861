import { Image, Layout, Tooltip } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MenuItem } from "src/types/types";

const { Content } = Layout;

type Props = {
  index: number;
  item: MenuItem;
  twoColumns: boolean;
  onClickEnabled?: boolean;
  onClick?: () => void;
};

export const MenuItemRow = (props: Props) => {
  const { index, item, twoColumns, onClickEnabled, onClick } = props;
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {})();
  }, []);

  const getMenuItemTitleWidth = (item: MenuItem): number => {
    const imageFactor = getMenuItemImageImageFactor(item);

    if (twoColumns) {
      if (item.secondPrice) {
        if (window.innerWidth < 325) {
          return 70 - imageFactor;
        }

        return ((item?.secondPrice || 0) > 9 ? 90 : 100) - imageFactor;
      }

      if (window.innerWidth < 325) {
        return 80 - imageFactor;
      }

      if ((item?.price || -1) < 0) {
        return 160 - imageFactor;
      }

      if (item.price || 0 > 9) {
        return 100 - imageFactor;
      }

      return 110 - imageFactor;
    } else {
      if (item.secondPrice) {
        if (window.innerWidth < 380) {
          if (window.innerWidth < 325) {
            return 160;
          }

          return (item?.secondPrice || 0) > 99 ? 195 : 205;
        } else {
          return (item?.secondPrice || 0) > 99 ? 230 : 238;
        }
      }

      if (window.innerWidth < 380) {
        if (window.innerWidth < 325) {
          return 210;
        }

        return 260;
      }

      if (item.imageUrl) {
        return 220;
      }

      return 275;
    }
  };

  const getMenuItemPriceWidth = (item: MenuItem): number => {
    if (twoColumns) {
      if (item.secondPrice) {
        return (item?.secondPrice || 0) > 0 ? 55 : 0;
      }
    }

    return 0;
  };

  const getMenuItemImageWidth = (item: MenuItem): number => {
    if (item.imageUrl) {
      if (twoColumns) {
        return 60;
      }

      return 80;
    }

    return 0;
  };

  const getMenuItemImageImageFactor = (item: MenuItem): number => {
    if (item.imageUrl) {
      return getMenuItemImageWidth(item) + 10;
    }

    return 0;
  };

  const hasDescription = item.description && item.description !== "";

  return (
    <Content
      key={`item-${index}`}
      style={{
        maxWidth: "900px",
        margin: "0 auto",
        textAlign: "left",
      }}
    >
      <p
        style={{
          marginBottom:
            !hasDescription && twoColumns && item.title.length > 30
              ? 10
              : hasDescription
              ? "0em"
              : 10,
        }}
      >
        {item.imageUrl && (
          <Image
            src={item.imageUrl}
            width={getMenuItemImageWidth(item)}
            style={{ paddingRight: 5 }}
            preview={!item.disablePreview}
          />
        )}
        <span
          className="item-title"
          onClick={() => {
            if (onClickEnabled && onClick) {
              onClick();
            }
          }}
          style={{
            fontWeight: 500,
            display: "inline-block",
            maxWidth: getMenuItemTitleWidth(item),
            lineHeight: !!(item?.spicy || item?.vegetarian || item?.vegan)
              ? "22px"
              : "18px",
            wordBreak: "break-word",
            textDecoration: onClickEnabled ? "underline" : "none",
            cursor: onClickEnabled ? "pointer" : "default",
          }}
        >
          {item.title}
          {!!(
            item?.spicy ||
            item?.vegetarian ||
            item?.vegan ||
            item?.new ||
            item?.top
          ) && (
            <sup>
              {!!item?.spicy && (
                <Tooltip placement="rightTop" title={t("tooltip.spicy")}>
                  <Image
                    style={{
                      width: 14,
                      height: 14,
                      margin: "0 auto",
                      marginLeft: 3,
                      marginTop: "-3px",
                    }}
                    src={"/assets/icons/spicy.png"}
                    preview={false}
                    fallback={""}
                  />
                </Tooltip>
              )}
              {!!item?.vegetarian && (
                <Tooltip placement="rightTop" title={t("tooltip.vegetarian")}>
                  <Image
                    style={{
                      width: 14,
                      height: 14,
                      margin: "0 auto",
                      marginLeft: 3,
                      marginTop: "-3px",
                    }}
                    src={"/assets/icons/vegetarian.png"}
                    preview={false}
                    fallback={""}
                  />
                </Tooltip>
              )}
              {!!item?.vegan && (
                <Tooltip placement="rightTop" title={t("tooltip.vegan")}>
                  <Image
                    style={{
                      width: 14,
                      height: 14,
                      margin: "0 auto",
                      marginLeft: 3,
                      marginTop: "-3px",
                    }}
                    src={"/assets/icons/vegan.png"}
                    preview={false}
                    fallback={""}
                  />
                </Tooltip>
              )}
              {!!item?.new && (
                <Tooltip placement="rightTop" title={t("tooltip.new")}>
                  <Image
                    style={{
                      width: 14,
                      height: 14,
                      margin: "0 auto",
                      marginLeft: 3,
                      marginTop: "-3px",
                    }}
                    src={"/assets/icons/new.png"}
                    preview={false}
                    fallback={""}
                  />
                </Tooltip>
              )}
              {!!item?.top && (
                <Tooltip placement="rightTop" title={t("tooltip.top")}>
                  <Image
                    style={{
                      width: 14,
                      height: 14,
                      margin: "0 auto",
                      marginLeft: 3,
                      marginTop: "-3px",
                    }}
                    src={"/assets/icons/top.png"}
                    preview={false}
                    fallback={""}
                  />
                </Tooltip>
              )}
            </sup>
          )}
        </span>
        <span
          className="item-price"
          style={{
            fontWeight: 500,
            float: "right",
            width:
              getMenuItemPriceWidth(item) > 0
                ? getMenuItemPriceWidth(item)
                : "inherit",
          }}
        >
          {(item?.price || -1) >= 0 && (
            <React.Fragment>
              {item.price}€
              {item.secondPrice ? " / " + item.secondPrice + "€" : ""}
            </React.Fragment>
          )}
        </span>
      </p>
      {hasDescription && (
        <p style={{ fontSize: 12 }}>
          {" "}
          <span
            className="item-description"
            style={{
              display: "inline-block",
              maxWidth: 270,
              lineHeight: "18px",
              wordBreak: "break-word",
            }}
          >
            {item.description}
          </span>
        </p>
      )}
    </Content>
  );
};
