import { CloseOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Image,
  Input,
  Layout,
  Modal,
  Select,
  Space,
  Upload,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAccessToken } from "src/authProvider";
import { API_BASE_URL } from "src/constants";
import { Spinner } from "src/core/components/Spinner";
import { menuDesignService } from "src/core/services/menuDesignService";
import { multiMenuService } from "src/core/services/multiMenuService";
import { textColors } from "src/core/style/colors";
import { MenuCustomBackgroundControl } from "src/modules/menuCustomBackground/MenuCustomBackgroundControl";
import { MenuDesign, MultiMenu } from "src/types/types";

type Props = {
  menuProps?: MultiMenu;
  activeState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  onSuccess?: () => void;
};

const { Option } = Select;
const { TextArea } = Input;

export const MultiMenuEditModal = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const [menuDesigns, setMenuDesigns] = useState<MenuDesign[]>();
  const [menu, setMenu] = useState<MultiMenu>({
    title: "",
    description: "",
    id: 0,
  });

  const [submit, setSubmit] = useState(false);

  const { t } = useTranslation();

  const {
    activeState: [active, setActive],
  } = props;

  const uploadProps = {
    name: "file",
    action: `${API_BASE_URL}/files/images/upload`,
    headers: {
      authorization: `Bearer ${getAccessToken()}`,
    },
    onChange(info: any) {
      if (info.file.status !== "uploading") {
        //console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        console.log(info.file);
        setMenu({
          ...menu,
          headerLogo: `${info.file.response.url}`,
        });
      } else if (info.file.status === "error") {
        // handle error
      }
    },
  };

  useEffect(() => {
    if (props.menuProps) {
      setMenu(props.menuProps);
    }
  }, [props.menuProps]);

  useEffect(() => {
    (async () => {
      const response = await menuDesignService.getMenuDesigns();
      setMenuDesigns(response);

      setLoading(false);
    })();
  }, []);

  const save = async () => {
    setSubmit(true);
    await multiMenuService.updateMultiMenu(menu);
    setSubmit(false);

    if (props.onSuccess) {
      props.onSuccess();
    }
  };

  return (
    <Modal
      open={active}
      title={t("modal.title.menuEdit")}
      footer={null}
      onCancel={() => {
        setActive(false);
      }}
    >
      <Layout>
        <Content style={{ background: "white" }}>
          <Input
            autoFocus
            placeholder={t("input.title")}
            value={menu.title}
            onChange={(evt) => {
              setMenu({ ...menu, title: evt.target.value });
            }}
            style={{ marginBottom: 20 }}
            required
          />
          <TextArea
            placeholder={t("input.descriptionOptional")}
            autoSize={{ minRows: 3, maxRows: 6 }}
            value={menu.description}
            onChange={(evt) => {
              setMenu({ ...menu, description: evt.target.value });
            }}
            style={{ marginBottom: 20 }}
          />
          <Select
            loading={loading}
            value={menu.menuDesignId}
            placeholder={t("input.selectStyle")}
            style={{ width: "100%", marginBottom: 20 }}
            onChange={(value: any) => {
              setMenu({ ...menu, menuDesignId: value });
            }}
          >
            {menuDesigns?.map((item: MenuDesign, index) => {
              return (
                <Option key={`mm-e-${index}`} value={item?.id || 0}>
                  {item?.title}
                </Option>
              );
            })}
          </Select>
          <Space style={{ marginBottom: 15 }}>
            <p style={{ marginBottom: 5 }}>
              <b>{t("input.menuLogo")}:</b>
            </p>
            {menu.headerLogo && menu.headerLogo !== "" ? (
              <Space>
                <Image
                  src={menu.headerLogo}
                  style={{ maxHeight: 80 }}
                  preview={false}
                />
                <CloseOutlined
                  onClick={() => {
                    setMenu({ ...menu, headerLogo: "" });
                  }}
                />
              </Space>
            ) : (
              <Upload
                {...uploadProps}
                accept={".png, .jpg, .jpeg"}
                maxCount={1}
                style={{ marginBottom: 20 }}
              >
                <Button icon={<UploadOutlined />}>{t("input.file")}</Button>
              </Upload>
            )}
            <Input
              type={"hidden"}
              placeholder={t("input.menuLogo")}
              value={menu.headerLogo}
              onChange={(evt) => {
                setMenu({ ...menu, headerLogo: evt.target.value });
              }}
              style={{ marginBottom: 20 }}
              required
            />
          </Space>
          <Space>
            {props.menuProps?.identifier && (
              <MenuCustomBackgroundControl
                menuIdentifier={props.menuProps?.identifier ?? ""}
                labelNote={t("message.menuCustomBackgroundNote")}
              />
            )}
          </Space>
        </Content>
        <Content style={{ textAlign: "right", background: "white" }}>
          <Button
            type="primary"
            style={{ width: 100 }}
            onClick={() => {
              save();
            }}
            disabled={false}
          >
            {submit ? (
              <Spinner size="small" color={textColors.white} />
            ) : (
              t("button.save")
            )}
          </Button>
        </Content>
      </Layout>
    </Modal>
  );
};
