import {
  AlignRightOutlined,
  CloseOutlined,
  FilePdfOutlined,
  UnorderedListOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Image,
  Input,
  Layout,
  Modal,
  Radio,
  Select,
  Space,
  Upload,
} from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getAccessToken } from "src/authProvider";
import { API_BASE_URL } from "src/constants";
import { Spinner } from "src/core/components/Spinner";
import { menuDesignService } from "src/core/services/menuDesignService";
import { menuService } from "src/core/services/menuService";
import { textColors } from "src/core/style/colors";
import { MenuType } from "src/enums";
import { MenuCustomBackgroundControl } from "src/modules/menuCustomBackground/MenuCustomBackgroundControl";
import { Menu, MenuDesign } from "src/types/types";

type Props = {
  menuProps?: Menu;
  activeState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  onSuccess?: () => void;
};

const { Option } = Select;
const { TextArea } = Input;

export const MenuEditModal = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const [menuDesigns, setMenuDesigns] = useState<MenuDesign[]>();
  const [menu, setMenu] = useState<Menu>({
    title: "",
    description: "",
    id: 0,
  });

  const [submit, setSubmit] = useState(false);
  const [currentMenuType, setCurrentMenuType] = useState(MenuType.SimpleMenu);

  const [titleRequiredError, setTitleRequiredError] = useState(false);
  const [pdfLinkRequiredError, setPdfLinkRequiredError] = useState(false);

  const { t } = useTranslation();
  const history = useHistory();

  const uploadProps = {
    name: "file",
    action: `${API_BASE_URL}/files/images/upload`,
    headers: {
      authorization: `Bearer ${getAccessToken()}`,
    },
    onChange(info: any) {
      if (info.file.status !== "uploading") {
        //console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        setMenu({
          ...menu,
          headerLogo: `${info.file.response.url}`,
        });
      } else if (info.file.status === "error") {
        // handle error
      }
    },
  };

  const menuBackgroundUploadProps = {
    name: "file",
    action: `${API_BASE_URL}/files/backgrounds/upload`,
    headers: {
      authorization: `Bearer ${getAccessToken()}`,
    },
    onChange(info: any) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        setMenu({
          ...menu,
          menuBackground: `${info.file.response.url}`,
        });
      } else if (info.file.status === "error") {
        // handle error
      }
    },
  };

  const pdfUploadProps = {
    name: "file",
    action: `${API_BASE_URL}/files/pdfs/upload`,
    headers: {
      authorization: `Bearer ${getAccessToken()}`,
    },
    onChange(info: any) {
      if (info.file.status !== "uploading") {
        //console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        setMenu({
          ...menu,
          pdfLink: `${info.file.response.url}`,
        });
      } else if (info.file.status === "error") {
        // handle error
      }
    },
  };

  const {
    activeState: [active, setActive],
  } = props;

  useEffect(() => {
    if (props.menuProps) {
      setMenu(props.menuProps);

      setCurrentMenuType(
        props.menuProps.pdfLink && props.menuProps.pdfLink !== ""
          ? MenuType.PdfMenu
          : MenuType.SimpleMenu
      );
    }
  }, [props.menuProps]);

  useEffect(() => {
    (async () => {
      const response = await menuDesignService.getMenuDesigns();
      setMenuDesigns(response);

      setLoading(false);
    })();
  }, []);

  const save = async () => {
    setTitleRequiredError(false);
    setPdfLinkRequiredError(false);

    if ((menu.title || "").trim() === "") {
      setTitleRequiredError(true);
      return;
    }

    if (
      currentMenuType === MenuType.PdfMenu &&
      (menu.pdfLink || "").trim() === ""
    ) {
      setPdfLinkRequiredError(true);
      return;
    }

    setSubmit(true);

    if (currentMenuType === MenuType.SimpleMenu) {
      menu.pdfLink = "";
    }

    await menuService.updateMenu(menu);

    setSubmit(false);

    if (props.onSuccess) {
      props.onSuccess();
    }
  };

  return (
    <Modal
      open={active}
      title={t("modal.title.menuEdit")}
      footer={null}
      onCancel={() => {
        setActive(false);
      }}
    >
      <Layout>
        <Content style={{ background: "white" }}>
          <Input
            autoFocus
            placeholder={t("input.title")}
            value={menu.title}
            onChange={(evt) => {
              setMenu({ ...menu, title: evt.target.value });
            }}
            style={{
              marginBottom: 20,
              borderColor: titleRequiredError ? "red" : undefined,
            }}
            required
          />
          <TextArea
            placeholder={t("input.descriptionOptional")}
            autoSize={{ minRows: 3, maxRows: 6 }}
            value={menu.description}
            onChange={(evt) => {
              setMenu({ ...menu, description: evt.target.value });
            }}
            style={{ marginBottom: 20 }}
          />
          <Radio.Group
            style={{ marginBottom: 20 }}
            value={currentMenuType}
            onChange={(e) => setCurrentMenuType(e.target.value)}
          >
            <Radio value={MenuType.SimpleMenu}>{t("label.simpleMenu")}</Radio>
            <Radio value={MenuType.PdfMenu}>{t("label.pdfMenu")}</Radio>
          </Radio.Group>
          {currentMenuType === MenuType.PdfMenu && (
            <Space style={{ marginBottom: 15, width: "100%" }}>
              <p style={{ marginBottom: 5 }}>
                <b>{t("input.pdfFile")}:</b>
              </p>
              {menu.pdfLink && menu.pdfLink !== "" ? (
                <Space>
                  <a href={menu.pdfLink} target="_blank" rel="noreferrer">
                    <FilePdfOutlined style={{ maxHeight: 40, fontSize: 18 }} />
                  </a>
                  <CloseOutlined
                    onClick={() => {
                      setMenu({ ...menu, pdfLink: "" });
                    }}
                  />
                </Space>
              ) : (
                <Upload
                  {...pdfUploadProps}
                  accept={".pdf"}
                  maxCount={1}
                  style={{ marginBottom: 20 }}
                >
                  <Button
                    style={{
                      borderColor: pdfLinkRequiredError ? "red" : undefined,
                    }}
                    icon={<UploadOutlined />}
                  >
                    {t("input.file")}
                  </Button>
                </Upload>
              )}
              <Input
                type={"hidden"}
                placeholder={t("input.pdfLink")}
                value={menu.pdfLink}
                onChange={(evt) => {
                  setMenu({ ...menu, pdfLink: evt.target.value });
                }}
                style={{ marginBottom: 20 }}
                required
              />
            </Space>
          )}
          {currentMenuType === MenuType.SimpleMenu && (
            <Select
              loading={loading}
              value={menu.menuDesignId}
              placeholder={t("input.selectStyle")}
              style={{ width: "100%", marginBottom: 20 }}
              onChange={(value: any) => {
                setMenu({ ...menu, menuDesignId: value });
              }}
            >
              {menuDesigns?.map((item: MenuDesign, index: number) => {
                return (
                  <Option key={`m-e-${index}`} value={item?.id || 0}>
                    {item?.title}
                  </Option>
                );
              })}
            </Select>
          )}
          {currentMenuType === MenuType.SimpleMenu && (
            <Space style={{ marginBottom: 15 }}>
              <p style={{ marginBottom: 5 }}>
                <b>{t("input.menuLogo")}:</b>
              </p>
              {menu.headerLogo && menu.headerLogo !== "" ? (
                <Space>
                  <Image
                    src={menu.headerLogo}
                    style={{ maxHeight: 80 }}
                    preview={false}
                  />
                  <CloseOutlined
                    onClick={() => {
                      setMenu({ ...menu, headerLogo: "" });
                    }}
                  />
                </Space>
              ) : (
                <Upload
                  {...uploadProps}
                  accept={".png, .jpg, .jpeg"}
                  maxCount={1}
                  style={{ marginBottom: 20 }}
                >
                  <Button icon={<UploadOutlined />}>{t("input.file")}</Button>
                </Upload>
              )}
              <Input
                type={"hidden"}
                placeholder={t("input.menuLogo")}
                value={menu.headerLogo}
                onChange={(evt) => {
                  setMenu({ ...menu, headerLogo: evt.target.value });
                }}
                style={{ marginBottom: 20 }}
                required
              />
            </Space>
          )}
          {false && currentMenuType === MenuType.SimpleMenu && (
            <Space style={{ marginBottom: 15 }}>
              <p style={{ marginBottom: 5 }}>
                <b>{t("input.menuBackground")}:</b>
              </p>
              {menu.menuBackground && menu.menuBackground !== "" ? (
                <Space>
                  <Image
                    src={menu.menuBackground}
                    style={{ maxHeight: 80 }}
                    preview={false}
                  />
                  <CloseOutlined
                    onClick={() => {
                      setMenu({ ...menu, menuBackground: "" });
                    }}
                  />
                </Space>
              ) : (
                <Upload
                  {...menuBackgroundUploadProps}
                  accept={".png, .jpg, .jpeg"}
                  maxCount={1}
                  style={{ marginBottom: 20 }}
                >
                  <Button icon={<UploadOutlined />}>{t("input.file")}</Button>
                </Upload>
              )}
              <Input
                type={"hidden"}
                placeholder={t("input.menuBackground")}
                value={menu.menuBackground}
                onChange={(evt) => {
                  setMenu({ ...menu, menuBackground: evt.target.value });
                }}
                style={{ marginBottom: 20 }}
                required
              />
            </Space>
          )}
          {currentMenuType === MenuType.SimpleMenu &&
            props.menuProps?.identifier && (
              <MenuCustomBackgroundControl
                menuIdentifier={props.menuProps?.identifier ?? ""}
                labelNote={t("message.menuCustomBackgroundNote")}
              />
            )}
          {currentMenuType === MenuType.SimpleMenu && (
            <Space style={{ width: "100%" }}>
              <p style={{ marginBottom: 20 }}>
                <b>{t("input.showSidebar")}:</b>
              </p>
              <Checkbox
                checked={menu.showSidebar}
                onChange={(value) => {
                  setMenu({
                    ...menu,
                    showSidebar: value.target.checked,
                  });
                }}
                style={{ marginBottom: 20 }}
              />
            </Space>
          )}
          {currentMenuType === MenuType.SimpleMenu && (
            <Space style={{ width: "100%" }}>
              <p style={{ marginBottom: 30 }}>
                <b>{t("input.ordersEnabled")}:</b>
              </p>
              <Checkbox
                checked={menu.ordersEnabled}
                onChange={(value) => {
                  setMenu({
                    ...menu,
                    ordersEnabled: value.target.checked,
                  });
                }}
                style={{ marginBottom: 30 }}
              />
            </Space>
          )}
        </Content>
        {currentMenuType === MenuType.SimpleMenu && (
          <Content
            style={{
              textAlign: "right",
              background: "white",
              paddingBottom: 15,
            }}
          >
            {!props.menuProps?.pdfLink && (
              <Button
                style={{ width: 160, marginRight: 20 }}
                onClick={() => {
                  history.push(
                    `/admin/categories?menuId=${props.menuProps?.id}`
                  );
                }}
                disabled={false}
              >
                <UnorderedListOutlined />
                {t("title.menuCategories")}
              </Button>
            )}
            {!props.menuProps?.pdfLink && (
              <Button
                style={{
                  width: 130,
                  marginRight: 0,
                }}
                onClick={() => {
                  history.push({
                    pathname: `/admin/menus/${props.menuProps?.id}/items`,
                    search: props.menuProps?.description
                      ? `?t=${props.menuProps?.title} (${props.menuProps?.description})`
                      : `?t=${props.menuProps?.title}`,
                  });
                }}
                disabled={false}
              >
                <AlignRightOutlined />
                {t("title.menuItems")}
              </Button>
            )}
          </Content>
        )}
        <Content style={{ textAlign: "right", background: "white" }}>
          <Button
            type="primary"
            style={{ width: 130 }}
            onClick={() => {
              save();
            }}
            disabled={false}
          >
            {submit ? (
              <Spinner size="small" color={textColors.white} />
            ) : (
              t("button.save")
            )}
          </Button>
        </Content>
      </Layout>
    </Modal>
  );
};
