import { API_BASE_URL } from "src/constants";
import { getUnauthorized, post } from "src/core/utils/api";
import { MenuCustomDesign, MenuDesign } from "src/types/types";

const createMenuDesignService = () => {
  const getMenuDesign = async (identifier: string): Promise<MenuDesign> => {
    const response = await getUnauthorized<MenuDesign>(
      API_BASE_URL + `/menus/${identifier}/menu-designs`
    );

    return response;
  };

  const getMenuCustomDesign = async (
    identifier: string
  ): Promise<MenuCustomDesign> => {
    const response = await getUnauthorized<MenuCustomDesign>(
      API_BASE_URL + `/menus/${identifier}/menu-designs/custom`
    );

    return response;
  };

  const saveMenuCustomDesign = async (
    identifier: string,
    data: MenuCustomDesign
  ): Promise<void> => {
    await post<MenuCustomDesign>(
      API_BASE_URL + `/menus/${identifier}/menu-designs/custom`,
      { menuIdentifier: identifier, backgroundImage: data.backgroundImage }
    );
  };

  const getMenuDesigns = async (): Promise<MenuDesign[]> => {
    const response = await getUnauthorized<MenuDesign[]>(
      API_BASE_URL + `/menu-designs`
    );

    return response.filter((el) => !el.private);
  };

  return {
    getMenuDesign,
    getMenuDesigns,
    getMenuCustomDesign,
    saveMenuCustomDesign,
  };
};

export const menuDesignService = createMenuDesignService();
